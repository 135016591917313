import "./index.css";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Divider } from "@mui/material";

const TopMenu = (props) => {
  /** VARS **/
  const {
    section
  } = props;
  /** EFFECTS **/

  /** HANDLERS **/

  /** RENDER **/
  return (
    <>
      <Grid className="NavMenu" container wrap="nowrap" justifyContent='end' direction='row-reverse' style={{zIndex: 5}}>
        <Grid Item>
          <Button href="#AboutUs" variant={section === 'AboutUs' ? 'contained' : 'text'} className="NavMenuButton">
            <Typography className="navMenuButtonText">
              אודות
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical"/>
        <Grid Item>
          <Button href="#Gallery" variant={section === 'Gallery' ? 'contained' : 'text'} className="NavMenuButton">
            <Typography className="navMenuButtonText">
              גלריה
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical"/>
        <Grid Item>
          <Button href="#Menu" variant={section === 'Menu' ? 'contained' : 'text'} className="NavMenuButton">
            <Typography className="navMenuButtonText">
              תפריט
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical"/>
        {/* <Grid Item>
          <Button className="NavMenuButton">
            <Typography className="navMenuButtonText">
              דרושים
            </Typography>
          </Button>
        </Grid> 
        <Divider orientation="vertical"/> */}
        <Grid Item>
          <Button href="#Contact" variant={section === 'Contact' ? 'contained' : 'text'} className="NavMenuButton">
            <Typography className="navMenuButtonText">
              צור קשר
            </Typography>
          </Button>
        </Grid>
        
      </Grid>

      <Grid
        className="TopMenuGrid"
        container
        wrap="nowrap"
        alignItems="start"
        direction="column"
      >
        <Grid item xs={4} className="TopMenuButtonGrid">
          <Button
            className="TopMenuButton"
            href="tel:0534334432"
            variant="contained"
            startIcon={<LocalPhoneIcon fontSize="small" className="titleIcons" />}
          >
          </Button>
        </Grid>
        <Grid item xs={2} md={4} className="TopMenuButtonGrid">
          <Button
            className="TopMenuButton"
            href="https://www.10bis.co.il/next/en/restaurants/menu/delivery/34505/bun-basar"
            variant="contained"
            color="primary"
            startIcon={<DeliveryDiningIcon className="titleIcons" />}
          >
          </Button>
        </Grid>
        <Grid item xs={4} className="TopMenuButtonGrid">
          <Button
            className="TopMenuButton"
            variant="contained"
            href="https://www.instagram.com/bun.basar/"
          >
            <InstagramIcon className="titleIcon" style={{ color: "black" }} />
          </Button>
        </Grid>
        <Grid item xs={4} className="TopMenuButtonGrid">
          <Button
            className="TopMenuButton"
            variant="contained"
            href="https://www.facebook.com/bunbasar-110358531350936"
          >
            <FacebookIcon className="titleIcon" style={{ color: "black" }} />
          </Button>
        </Grid>
        <Grid item xs={4} className="TopMenuButtonGrid">
          <Button
            className="TopMenuButton"
            variant="contained"
            href="https://goo.gl/maps/vipULKPYEtHyzRD18"
          >
            <DirectionsIcon className="titleIcon" style={{ color: "black" }} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

/** EXPORT **/
export default TopMenu;
