/* eslint-disable no-lone-blocks */
import './index.css';

import { Accessibility } from 'accessibility/dist/main';

import Grid from '@mui/material/Grid';

import TopMenu from '../TopMenu';
import AboutUs from '../AboutUs';
import Menu from '../Menu';
import Gallery from '../Gallery';

// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';

import openingHours from '../TopMenu/openingHours.json';
import { Typography } from '@mui/material';
import DividerWithText from '../DividerWithText';
import { useEffect, useRef, useState } from 'react';

const Divider = (text) => {
    return (
      <div className="container">
        <div className="border" />
        <span className="content">
          {text}
        </span>
        <div className="border" />
      </div>
    );
  };

const getOpeningHours = () => {
    let res = []
    let days = []
    
    Object.entries(openingHours[0]).forEach((day) => {
        let dayName = day[0];
        let dayHours = day[1];

        days.push(
        <Grid item>
            <Typography dir='rtl' style={{whiteSpace: 'pre', fontWeight: 'bold', fontSize: '3vw', fontFamily: 'myriad-hebrew'}}> {/* whiteSpace: 'pre' means don't collapse white spaces.*/}
                {dayHours[0] !== "Closed"
                ? <>{dayName.padEnd(5)}:{dayHours[0].padEnd(5)} - {dayHours[1].padEnd(5)}</> // There are tabs instead of spaces (ALT + 0 + 9).
                : <>{dayName.padEnd(5)}:סגור</>
                }
            </Typography>
        </Grid>
        )
    });
    res.push(
        <Grid container direction='column'>
            <Grid item>
                <Typography  dir='rtl' style={{fontWeight: 'bold', fontSize: '4vw', fontFamily: 'myriad-hebrew'}}>
                    שעות פעילות
                </Typography>
            </Grid>
            {days}
        </Grid>
    );
    return res;
}

// const slideImages = [
//     {
//         url: '/Gallery/2.jpg',
//         caption: 'Slide 1'
//     },
//     {
//         url: '/Gallery/5.jpg',
//         caption: 'Slide 2'
//     },
//     {
//         url: '/Gallery/1.jpg',
//         caption: 'Slide 3'
//     },
// ]

// const Sildeshow = () => {
//     return (
//         <div className="slide-container">
//             <Slide>
//                 {slideImages.map((slideImage, index) => (
//                     <div className="each-slide" key={index}>
//                         <div style={{backgroundImage: `url('${slideImage.url}')`}}>
//                             <span>{slideImage.caption}</span>
//                         </div>
//                     </div>
//                 ))}
//             </Slide>
//         </div>
//     )
// }

const App = (props) => {
    /** VARS **/
    const [section, setSection] = useState(null);
    const aboutUsRef = useRef();
    const galleryRef = useRef();
    const menuRef = useRef();
    const contactRef = useRef();
    /** EFFECTS **/
    useEffect(() => {
        window.addEventListener('load', function() { new Accessibility(); }, false);
        return () => window.removeEventListener('load', function() { new Accessibility(); }, false);
    })

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    })
    
    /** HANDLERS **/
    const scrollHandler = () => {
        if (window.pageYOffset + window.innerHeight >= contactRef.current.offsetTop){ // Contact is visible.
            setSection('Contact');
        }
        else if (window.pageYOffset + 10 >= menuRef.current.offsetTop) { // Page scroll is under the menu section.
            setSection('Menu');
        }
        else if (window.pageYOffset + 10 >= galleryRef.current.offsetTop) { // Page scroll is under the gallery section.
            setSection('Gallery');
        }
        else if (window.pageYOffset + 10 >= aboutUsRef.current.offsetTop) { // Page scroll is under the about us section.
            setSection('AboutUs');
        }
        else {
            setSection(null);
        }
    }

    /** RENDER **/
    return (
        <div className='App'>
            {/* <div className='bgImage' style={{backgroundImage: `url(${image})`}}> */}
            <div>
                {/* <div className='AppTopMenu'>
                    <TopMenu />
                </div> */}
                <div className='TopPage'>
                    <img className='TopPageLogo' src='logo.png' alt='Bun Basar' />
                    
                    {/* <div className='TopPageText'>
                        <div>
                            Welcome To
                        </div>
                        <div style={{fontFamily: "Asimon AAA"}}>
                            <span style={{fontSize: '14.1vw', color: '#231f20'}}>באנ</span>
                            <span style={{fontSize: '14.1vw', color: 'white'}}>בשר</span>
                        </div>
                        <div style={{fontfontFamily: 'Marisa Fixed'}}>
                            050-22236363
                        </div>
                    </div> */}
                </div>
                <div className='TopPageBackground' style={{backgroundImage: `url('newPhotos/TopPageBackground.png')`}}>
                    {/* <Sildeshow /> */}
                    <TopMenu section={section}/>
                    <div style={{fontFamily: "Asimon AAA", textAlign: 'center', top: '10vh', position: 'relative'}}>
                        <span style={{fontSize: '27vw', color: 'white'}}>באנבשר</span>
                    </div>
                </div>
                <div ref={aboutUsRef}>
                    <AboutUs />
                </div>
                {/* <div className='GalleryImageDiv' style={{backgroundColor: '#274149'}}> */}
                    {/* <div style={{width: '90%', margin: '0 auto', position: 'relative', top: '50px'}}> */}
                    <div ref={galleryRef}>
                        <Gallery />
                    </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
            <div ref={menuRef}>
                <Menu />
            </div>
            <div className='BottomPage' ref={contactRef} id="Contact" style={{height: '80%'}}>
                <Grid container justifyContent='space-evenly' alignItems='center' textAlign='center' style={{position: 'relative', top: '2%'}}>
                    <Grid item xs={3}>
                        {getOpeningHours()}
                    </Grid>
                    <Grid item xs={3}>
                        <div className='BottomPageText'>
                            רוטשילד 45, כפר סבא
                            <br/>
                            ישראל
                        </div>
                        <br/>
                        <div className='BottomPageText' style={{fontWeight: 'bold'}}>
                            Rotschild 45
                            <br/>
                            Kfar Saba Israel
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{direction: 'rtl', fontWeight: 'bold', fontSize: '5vw', fontFamily: 'myriad-hebrew'}}>
                            טלפון
                        </Typography>
                        <Typography style={{fontSize: '5vw', fontFamily: 'myriad-hebrew'}}>
                            053-433-4432
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

/** EXPORT **/
export default App