import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './Components/App'

import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#819c8f',
      background: '#566960',
    },
    secondary: {
      main: '#3b636f',
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme = {theme}>
      <App/>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);