import { Paper, Grid, Box, Typography } from "@mui/material";

import menuItems from "./Menu.json";

import "./index.css";
import DividerWithText from "../../DividerWithText";

const MenuItems = (props) => {
  /** VARS **/
  let res = [];
  let itemsRes = [];

  Object.entries(menuItems).forEach((category) => {
    Object.entries(category[1]).forEach((item, idx) => {
      itemsRes.push(
        <Grid
          item
          xs={
            category[0] === "שתייה"
            ? 3
            : window.screen.width > 800 ? 3 : 5
          }
          // xs={
          //   // idx === Object.entries(category[1]).length - 1 && idx % 2 === 0
          //   //   ? 10
          //   //   : 5
          //   3
          // }
        >
          {/* If item is the last item and only item in its row, give it full length, otherwise half. */}
          {item[1].Image !== "" ?
            <Paper
              className="PaperMenuItem"
              // elevation={3}
              elevation={0}
              // style={{ background: '#dfd4bd' }}
            >
              <Box style={{position: 'relative', display: 'inline-block', overflow: 'hidden'}}>
                  <img
                    className="MenuItemImage"
                    src={process.env.PUBLIC_URL + "MenuItems/" + item[1].Image}
                    alt={item[0]}
                    // style={{borderRadius: '50%'}}
                    />
                  {/* <Box className="MenuItemPriceWithImage">{item[1].Price}</Box> */}
                  {item[1].Vegan 
                    ? <img src="MenuItems/Vegan.png" alt="Vegan" className="veganImage"/>
                    : <></>
                  }
              </Box>
              <Typography variant="h4">
                <Box className="MenuItemText MenuItemName" style={{marginBottom: '20px'}}>
                  {item[0]} {/* Item Name */}
                </Box>
              </Typography>
              <Box className="MenuItemText MenuItemDescription">
                {item[1].Description}
              </Box>
              <br />
              <br />
            </Paper>
            :
            <Paper
              className="PaperMenuItem"
              // elevation={3}
              elevation={0}
              style={{ background: '#f3e29c' }}
            >
              <Typography variant="h4" style={{fontFamily: 'myriad-hebrew'}}>
                <Box className="MenuItemText MenuItemName">
                  {item[0]} {/* Item Name */}
                </Box>
              </Typography>
              <br />
              <br />
              {/* <Box className="MenuItemPrice MenuItemDrinkPrice">{item[1].Price}</Box> */}
            </Paper> 
            }
        </Grid> 
      );
    });
    res.push(
      <>
        <br />
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          spacing={1}
        >
          <Grid item xs={10}>
            <Typography variant="h3" className="CategoryTitle" style={{fontFamily: 'myriad-hebrew'}}>
              <DividerWithText text={category[0]} />
            </Typography>
          </Grid>
          {itemsRes}
        </Grid>
        <br />
      </>
    );
    itemsRes = [];
  });

  /** EFFECTS **/
  { }

  /** HANDLERS **/
  { }

  /** RENDER **/
  return (
    <div className="MenuDiv">
      <Typography variant="h1" className="MenuTitle">
        <DividerWithText text="תפריט" />
      </Typography>
      {res}
    </div>
  )
};

/** EXPORT **/
export default MenuItems;
