import MenuItems from "./MenuItems";

const Menu = (props) => {
    /** VARS **/

    /** EFFECTS **/
    { }
    
    /** HANDLERS **/
    { }

    /** RENDER **/
    return (
        <div id="Menu">
            <MenuItems />
        </div>
    )
}

/** EXPORT **/
export default Menu