import "react-awesome-slider/dist/styles.css";
import "./index.css";

import { Grid, Typography } from "@mui/material";
import DividerWithText from "../DividerWithText";

const Gallery = (props) => {
  /** VARS **/

  /** EFFECTS **/
  {
  }

  /** HANDLERS **/
  {
  }

  /** RENDER **/
  return (
    <div className="GalleryDiv" id="Gallery">
      <Typography
        className="GalleryTitle"
        style={{ fontFamily: "myriad-hebrew"}}
      >
        <DividerWithText text="גלריה" />
      </Typography>
      {/* {slider} */}
      <div
        style={{
          width: "99%",
          top: "50px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Grid container justifyContent="center" wrap="nowrap">
          <Grid Item>
            <img
              src="/Gallery/2.jpg"
              alt="Gallery 1"
              className="NewGalleryImage NewGalleryImageLong"
            />
          </Grid>
          <Grid Item>
            <img
              src="/Gallery/7.jpg"
              alt="Gallery 1"
              className="NewGalleryImage NewGalleryImageLong"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" wrap="nowrap">
          <Grid Item>
            <img
              src="/Gallery/0.jpg"
              alt="Gallery 1"
              className="NewGalleryImage"
            />
          </Grid>
          <Grid Item>
            <img
              src="/Gallery/3.jpg"
              alt="Gallery 1"
              className="NewGalleryImage"
            />
          </Grid>
          <Grid Item>
            <img
              src="/Gallery/5.jpg"
              alt="Gallery 1"
              className="NewGalleryImage"
            />
          </Grid>
          <Grid Item>
            <img
              src="/Gallery/4.jpg"
              alt="Gallery 1"
              className="NewGalleryImage"
            />
          </Grid>
          <Grid Item>
            <img
              src="/Gallery/1.jpg"
              alt="Gallery 1"
              className="NewGalleryImage"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

/** EXPORT **/
export default Gallery;
