import './index.css'

import Typography from '@mui/material/Typography';
import DividerWithText from '../DividerWithText';

const AboutUs = (props) => {
    /** VARS **/

    /** EFFECTS **/
    { }
    
    /** HANDLERS **/
    { }

    /** RENDER **/
    return (
        <div className='AboutDiv' id="AboutUs">
            <img className='Yoni' src='newPhotos/Yoni.png' alt='Yoni'></img>
            <div className='AboutText'>
                <Typography className='AboutTextHeadline' style={{fontFamily: 'myriad-hebrew'}}>
                    <span>
                        <DividerWithText text="אודות" />
                    </span>
                </Typography>
                <Typography className='AboutTextBody' style={{fontFamily: 'myriad-hebrew', fontWeight: 'bold'}}>
                באנבשר היא מסעדת באנים בכפר סבא,
                </Typography>
                <Typography className='AboutTextBody' style={{fontFamily: 'myriad-hebrew', marginBottom: '1vw'}}>
                המגישה חוויה של טעמים עם בשר משובח בלחמניה מאודה.
                </Typography>
                <Typography className='AboutTextBody' style={{fontFamily: 'myriad-hebrew'}}>
                אנחנו עובדים רק עם חומרי גלם איכותיים וטריים, ומגישים מנות המתמחות בבישול בשיטת ואקום ובישול ארוך. 
                </Typography>
                <Typography className='AboutTextBody' style={{fontFamily: 'myriad-hebrew'}}>
                תפריט המסעדה מבוסס על מנות בשר איכותיות ומגוונות: אסאדו, קורנדביף, המבורגר מפורק. בנוסף, ניתן למצוא כמובן מנות לצמחונים / טבעונים.
                </Typography>
                <Typography className='AboutTextBody' style={{fontFamily: 'myriad-hebrew'}}>
                לצד המנה ניתן לקבל תוספות בריאות ואפויות עם מינימום שמן !
                </Typography>
            </div>
        </div>
            // <Paper className='AboutPaper' elevation={3} style={{background: theme.palette.primary.main}}>
            //     <Typography variant='h6' dir='rtl'>
            //         <Box className='AboutBoxTextBold'>
            //         לחמניות באן במילוי בשר אסאדו, קורנדביף והמבורגר בשיטת סו-ויד ואפייה.
            //         </Box>
            //         <Box className='AboutBoxTextRegular'>
            //         באנבשר היא מסעדת באנים בכפר סבא,
            //         </Box>
            //         <Box className='AboutBoxTextRegular'>
            //         המגישה חוויה של טעמים עם בשר משובח בלחמניה מאודה.
            //         </Box>
            //         <Box className='AboutBoxTextBold'>
            //             אנחנו עובדים רק עם חומרי גלם איכותיים וטריים, ומגישים מנות המתמחות בבישול בשיטת ואקום ובישול ארוך.
            //         </Box>
            //         <Box className='AboutBoxTextRegular'>
            //         תפריט המסעדה מבוסס על מנות בשר איכותיות ומגוונות: אסאדו, קורנדביף, המבורגר.
            //         </Box>
            //         <Box className='AboutBoxTextRegular'>
            //         בנוסף, ניתן למצוא כמובן מנות לצמחונים / טבעונים.
            //         </Box>
            //         <Box className='AboutBoxTextRegular'>
            //         לצד המנה ניתן לקבל תוספות בריאות ואפויות עם מינימום שמן!
            //         </Box>
            //     </Typography>
            // </Paper>
    )
}

/** EXPORT **/
export default AboutUs