import './index.css';

const DividerWithText = (props) => {
    /** VARS **/
    const {
        classes,
        text
    } = props

    /** EFFECTS **/
    { }
    
    /** HANDLERS **/
    { }

    /** RENDER **/
    return (
        <div className="container">
            <div className="border" />
            <span className="content">
              {text}
            </span>
            <div className="border" />
          </div>
    )
}

/** EXPORT **/
export default DividerWithText;